<template>
  <v-dialog v-if="isSpotAdmin" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="icon" v-on="on" icon small class="ma-2" color="error"
        ><v-icon>{{ $vuetify.icons.values.mdiClose }}</v-icon></v-btn
      >
      <v-btn v-else v-on="on" x-small outlined class="ma-2" color="error"
        >Remove
        <v-icon dark>{{ $vuetify.icons.values.mdiClose }}</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title class="headline green white--text" primary-title>
        <span>Confirm Remove Spot</span>
      </v-card-title>
      <v-card-text>
        <div class="text-h6">
          Are you sure that you want to remove this spot? [{{ spotId }}]
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="confirm">Yes</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "SpotRemove",
  props: {
    spotId: { default: 0 },
    icon: { default: false, type: Boolean },
  },
  data: function () {
    return {
      dialog: false,
    };
  },
  computed: {
    isSpotAdmin: {
      get() {
        return this.$store.state.user.isSpotAdmin;
      },
    },
  },
  methods: {
    confirm() {
      axios
        .delete(
          `https://${process.env.VUE_APP_API_URL}/v1/spots/${this.spotId}`,
          this.$store.getters.authTokenHeader
        )
        .then(() => {
          this.$store.dispatch("getSpots");
        });

      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>

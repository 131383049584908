import { render, staticRenderFns } from "./SpotHistory.vue?vue&type=template&id=2a2989dc&scoped=true&"
import script from "./SpotHistory.vue?vue&type=script&lang=js&"
export * from "./SpotHistory.vue?vue&type=script&lang=js&"
import style0 from "./SpotHistory.vue?vue&type=style&index=0&id=2a2989dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2989dc",
  null
  
)

export default component.exports
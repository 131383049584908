<template>
  <ul class="horizontal-list horizontal-list-container">
    <li>
      <filter-drop-menu
        name="Band"
        v-model="bandFilter"
        :count="bandFilteredCount"
        :options="activeBands"
      ></filter-drop-menu>
    </li>
    <li>
      <filter-drop-menu
        name="Mode"
        v-model="modeFilter"
        :count="modeFilteredCount"
        :options="activeModes"
      ></filter-drop-menu>
    </li>
    <li>
      <filter-drop-menu
        name="Program"
        v-model="programFilter"
        :count="programFilteredCount"
        :options="activePrograms"
      ></filter-drop-menu>
    </li>
    <li>
      <filter-drop-menu
        name="QRT"
        v-model="qrtFilter"
        :count="qrtFilteredCount"
        :options="qrtFilters"
      ></filter-drop-menu>
    </li>
    <li>
      <filter-drop-menu
        name="Hunted"
        v-model="huntedFilter"
        :count="huntedFilteredCount"
        :options="huntedFilters"
      ></filter-drop-menu>
    </li>
    <li>
      <filter-drop-menu
        name="Sort"
        v-model="sortSelect"
        :options="sortOptions"
        nocount
      ></filter-drop-menu>
    </li>
  </ul>
</template>

<style scoped>
.horizontal-list {
  list-style-type: none;
  overflow: hidden;
}
.horizontal-list li.heading {
  font-weight: bold;
}
.horizontal-list li {
  float: left;
  margin-right: 0;
}
.horizontal-list-container {
  margin: 12px 0 12px -12px;
}
.v-btn {
  text-transform: none !important;
  padding-left: 8px;
  padding-right: 2px;
}
.v-btn b {
  font-weight: bolder;
  padding-right: 8px;
}
</style>

<script>
import FilterDropMenu from "@/components/FilterDropMenu";

export default {
  components: {
    FilterDropMenu,
  },
  computed: {
    spots: {
      get() {
        return this.$store.state.spots.spots; // use all spots, not already filtered spots!
      },
    },
    bands: {
      get() {
        return this.$store.state.adif.potaBands;
      },
    },
    bandFilter: {
      get() {
        return this.$store.state.spots.bandFilter;
      },
      set(value) {
        this.$store.commit("SET_BAND_FILTER", value);
      },
    },
    bandFilteredCount: {
      get() {
        var filter = this.bandFilter;
        if (!filter) filter = "All";
        var active = this.activeBands.filter((item) => item.value == filter);
        if (active == null || active.length == 0) return 0;
        return active[0].count;
      },
    },
    modeFilter: {
      get() {
        return this.$store.state.spots.modeFilter;
      },
      set(value) {
        this.$store.commit("SET_MODE_FILTER", value);
      },
    },
    modeFilteredCount: {
      get() {
        var filter = this.modeFilter;
        if (!filter) filter = "All";
        var active = this.activeModes.filter((item) => item.value == filter);
        if (active == null || active.length == 0) return 0;
        return active[0].count;
      },
    },
    programFilter: {
      get() {
        return this.$store.state.spots.programFilter;
      },
      set(value) {
        this.$store.commit("SET_PROGRAM_FILTER", value);
      },
    },
    programFilteredCount: {
      get() {
        var filter = this.programFilter;
        if (!filter) filter = "All";
        var active = this.activePrograms.filter((item) => item.value == filter);
        if (active == null || active.length == 0) return 0;
        return active[0].count;
      },
    },
    huntedFilter: {
      get() {
        return this.$store.state.spots.huntedFilter;
      },
      set(value) {
        this.$store.commit("SET_HUNTED_FILTER", value);
      },
    },
    huntedFilteredCount: {
      get() {
        var filter = this.huntedFilter;
        if (!filter) filter = "Show";
        var active = this.huntedFilters.filter((item) => item.value == filter);
        if (active == null || active.length == 0) return 0;
        return active[0].count;
      },
    },
    qrtFilter: {
      get() {
        return this.$store.state.spots.qrtFilter;
      },
      set(value) {
        this.$store.commit("SET_QRT_FILTER", value);
      },
    },
    qrtFilteredCount: {
      get() {
        var filter = this.qrtFilter;
        if (!filter) filter = "Show";
        var active = this.qrtFilters.filter((item) => item.value == filter);
        if (active == null || active.length == 0) return 0;
        return active[0].count;
      },
    },
    activeBands: function () {
      var active = [];
      var spots = this.spots;
      var filter = this.bandFilter;

      // if (this.modeFilter != null && this.modeFilter != 'All')
      //   spots = spots.filter(x => x.mode == this.modeFilter);

      // if (this.programFilter != null && this.programFilter != 'All')
      //   spots = spots.filter(x => x.program == this.programFilter);

      // if (this.qrtFilter != null && this.qrtFilter != 'Show')
      //   spots = spots.filter(x => !x.qrt);

      // if (this.huntedFilter != null && this.huntedFilter != 'Show')
      //   spots = spots.filter(x => !x.hunted);

      active.push({ value: "All", count: spots.length });

      this.bands.forEach(function (filterBand) {
        // can't use filter() inside the forEach loop
        var count = 0;

        for (var i = 0; i < spots.length; i++) {
          if (spots[i].band == filterBand) count++;
        }

        if (count > 0 || filterBand == filter)
          active.push({ value: filterBand, count: count });
      });

      var other = spots.filter((x) => x.band == "Other").length;
      if (other > 0) active.push({ value: "Other", count: other });

      return active;
    },
    activeModes: function () {
      var spots = this.spots;

      // if (this.bandFilter != null && this.bandFilter != 'All')
      //   spots = spots.filter(x => x.band == this.bandFilter);

      // if (this.programFilter != null && this.programFilter != 'All')
      //   spots = spots.filter(x => x.program == this.programFilter);

      // if (this.qrtFilter != null && this.qrtFilter != 'Show')
      //   spots = spots.filter(x => !x.qrt);

      // if (this.huntedFilter != null && this.huntedFilter != 'Show')
      //   spots = spots.filter(x => !x.hunted);

      var modes = [];
      for (var i = 0; i < spots.length; i++) {
        if (!modes.includes(spots[i].mode)) modes.push(spots[i].mode);
      }
      if (this.modeFilter != "All" && !modes.includes(this.modeFilter))
        modes.push(this.modeFilter);
      modes.sort();

      var active = [];
      active.push({ value: "All", count: spots.length });

      var current_data_count = 0;
      var current_phone_count = 0;
      for (var m = 0; m < modes.length; m++) {
        var count = 0;
        for (var s = 0; s < spots.length; s++) {
          if (spots[s].mode == modes[m]) count++;
        }
        if (modes[m] != "DATA" && modes[m] != "PHONE") {
          active.push({ value: modes[m], count: count });
        }

        // Total up DATA spots
        if (this.$store.state.adif.dataModes.includes(modes[m])) {
          current_data_count += count;
        }
        // Total up DATA spots
        if (this.$store.state.adif.phoneModes.includes(modes[m])) {
          current_phone_count += count;
        }
      }
      active.push({ value: "DATA", count: current_data_count });
      active.push({ value: "PHONE", count: current_phone_count });

      return active;
    },
    activePrograms: function () {
      var spots = this.spots;

      // if (this.bandFilter != null && this.bandFilter != 'All')
      //   spots = spots.filter(x => x.band == this.bandFilter);

      // if (this.modeFilter != null && this.modeFilter != 'All')
      //   spots = spots.filter(x => x.mode == this.modeFilter);

      // if (this.qrtFilter != null && this.qrtFilter != 'Show')
      //   spots = spots.filter(x => !x.qrt);

      // if (this.huntedFilter != null && this.huntedFilter != 'Show')
      //   spots = spots.filter(x => !x.hunted);

      var programs = [];
      for (var i = 0; i < spots.length; i++) {
        if (!programs.includes(spots[i].program))
          programs.push(spots[i].program);
      }
      if (this.programFilter != "All" && !programs.includes(this.programFilter))
        programs.push(this.programFilter);
      programs.sort();

      var active = [];
      active.push({ value: "All", count: spots.length });

      for (var p = 0; p < programs.length; p++) {
        var count = 0;
        for (var s = 0; s < spots.length; s++) {
          if (spots[s].program == programs[p]) count++;
        }
        active.push({ value: programs[p], count: count });
      }

      return active;
    },
    qrtFilters: function () {
      var spots = this.spots;

      // if (this.bandFilter != null && this.bandFilter != 'All')
      //   spots = spots.filter(x => x.band == this.bandFilter);

      // if (this.modeFilter != null && this.modeFilter != 'All')
      //   spots = spots.filter(x => x.mode == this.modeFilter);

      // if (this.programFilter != null && this.programFilter != 'All')
      //   spots = spots.filter(x => x.program == this.programFilter);

      // if (this.huntedFilter != null && this.huntedFilter != 'Show')
      //   spots = spots.filter(x => !x.hunted);

      var show = spots.length;
      var hide = spots.filter((x) => !x.qrt).length;

      var filters = [];
      filters.push({ value: "Show", count: show });
      filters.push({ value: "Hide", count: hide });

      return filters;
    },
    huntedFilters: function () {
      var spots = this.spots;

      // if (this.bandFilter != null && this.bandFilter != 'All')
      //   spots = spots.filter(x => x.band == this.bandFilter);

      // if (this.modeFilter != null && this.modeFilter != 'All')
      //   spots = spots.filter(x => x.mode == this.modeFilter);

      // if (this.programFilter != null && this.programFilter != 'All')
      //   spots = spots.filter(x => x.program == this.programFilter);

      // if (this.qrtFilter != null && this.qrtFilter != 'Show')
      //   spots = spots.filter(x => !x.qrt);

      var show = spots.length;
      var hide = spots.filter((x) => !x.hunted).length;

      var filters = [];
      filters.push({ value: "Show", count: show });
      filters.push({ value: "Hide", count: hide });

      return filters;
    },
    sortSelect: {
      get() {
        return this.$store.state.spots.sort;
      },
      set(value) {
        this.$store.commit("SET_SORT", value);
      },
    },
  },
  data() {
    return {
      sortOptions: [
        { value: "Time" },
        { value: "Activator" },
        { value: "Frequency" },
        { value: "Mode" },
        { value: "Reference" },
        { value: "Park Name" },
        { value: "Region" },
        { value: "Spotter" },
      ],
    };
  },
};
</script>

<template>
  <v-layout row wrap>
    <v-flex xs12>
      <div>
        <span class="title text-xs-left primary--text">Active Spots</span>
        <span class="refresh-timer" v-if="!pause"
          ><em>Data will refresh in {{ refreshCountdown }} seconds.</em></span
        >
        <span class="refresh-timer" v-else
          ><em>Data refresh is <b>paused</b>.</em></span
        >
        <!--
           <v-btn @click="setPause(true)" plain rounded x-small>Pause</v-btn>
           <v-btn @click="setPause(false)" color="red" rounded x-small>Unpause</v-btn>
           -->
      </div>
      <spot-filter />
      <spot-list />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import SpotList from "@/components/SpotList";
import SpotFilter from "@/components/SpotFilter";

export default {
  mounted() {
    this.$store.dispatch("getSpots");
  },
  created() {},
  computed: mapState({
    spots: (state) => state.spots.spots,
    loadingSpots: (state) => state.spots.loading,
    tableView: (state) => state.config.tableView,
    pause: (state) => state.spots.pause,
  }),
  components: {
    SpotList,
    SpotFilter,
  },
  data() {
    return {
      refreshCountdown: 60,
      refreshTimer: null,
      refreshHuntedCountdown: 300,
      resetHuntedCountdown: 10,
      search: "",
      currentUtcDay: this.$dayjs.utc().format("YYYY-MM-DD"),
      headers: [
        {
          text: "Time",
          value: "activeCallsign",
          align: "left",
          sortable: false,
        },
        { text: "Activator", value: "activator", align: "left" },
        {
          text: "Frequency",
          value: "frequencies",
          align: "left",
          sortable: false,
        },
        {
          text: "Reference",
          value: "reference",
          align: "left",
          sortable: false,
        },
        { text: "State", value: "state", align: "left", sortable: false },
        { text: "Spotter", value: "spotter", align: "left", sortable: false },
        { text: "Comments", value: "comments", align: "left", sortable: false },
      ],
    };
  },
  watch: {
    refreshCountdown: {
      handler(value) {
        if (value == 0) {
          if (!this.pause) this.$store.dispatch("getSpots");
          this.refreshCountdown =
            this.spots && this.spots.length > 0 ? 60 : 120;
        }

        if (this.refreshHuntedCountdown == 0) {
          this.$store.dispatch("getHunted");
          this.refreshHuntedCountdown = 600;
        }

        if (this.resetHuntedCountdown == 0) {
          var utcDay = this.$dayjs.utc().format("YYYY-MM-DD");
          if (utcDay != this.currentUtcDay) this.$store.dispatch("resetHunted");
          this.resetHuntedCountdown = 10;
        }

        if (this.refreshTimer == null) {
          this.refreshTimer = setInterval(() => {
            this.refreshCountdown--;
            this.refreshHuntedCountdown--;
            this.resetHuntedCountdown--;
          }, 1000);
        }
      },
      immediate: true, // start on creation
    },
  },
  methods: {
    setPause(value) {
      this.$store.state.spots.pause = value;
    },
  },
  beforeDestroy() {
    if (this.refreshTimer != null) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.refresh-timer {
  padding-left: 20px;
  font-size: smaller;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

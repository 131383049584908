<template>
  <v-layout row wrap>
    <v-flex xs12>
      <div>
        <span class="title text-xs-left primary--text"
          >Scheduled Activations</span
        >
      </div>
      <activation-filter />
      <activation-table
        v-bind:activations="activations"
        v-bind:loadingActivations="loadingActivations"
        hide-actions
        v-if="$vuetify.breakpoint.smAndUp && tableView"
      />
      <activation-card
        v-bind:activations="activations"
        v-bind:loadingActivations="loadingActivations"
        v-if="$vuetify.breakpoint.xsOnly || !tableView"
      />
      <!-- <activation-form/> -->
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
//import ActivationForm from '@/components/ActivationForm'
import ActivationTable from "@/components/ActivationTable";
import ActivationCard from "@/components/ActivationCard";
import ActivationFilter from "@/components/ActivationFilter";

export default {
  mounted() {
    this.$store.dispatch("getActivationsv1");
  },
  computed: mapState({
    activations: (state) => state.activations.filtered,
    loadingActivations: (state) => state.activations.loading,
    tableView: (state) => state.config.tableView,
  }),
  components: {
    //ActivationForm,
    ActivationTable,
    ActivationCard,
    ActivationFilter,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "End Date",
          value: "activeCallsign",
          align: "left",
          sortable: false,
        },
        { text: "Activator", value: "activator", align: "left" },
        {
          text: "Frequencies",
          value: "frequencies",
          align: "left",
          sortable: false,
        },
        {
          text: "Reference",
          value: "reference",
          align: "left",
          sortable: false,
        },
        { text: "Comments", value: "comments", align: "left", sortable: false },
      ],
    };
  },
};
</script>

<template>
  <v-layout row wrap class="quiet-links">
    <v-progress-linear
      xs12
      indeterminate
      color="primary"
      v-show="loadingSpots == true"
    ></v-progress-linear>
    <!-- If no spots, display this -->
    <!--
    <v-flex xs12 sm6 lg4 v-if="spots.length == 0" class="text-xs-center">
      <v-card>
        <v-card-title primary-title class="title">
          No current spots
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div>No one has been spotted in the last 30 minutes.</div>
          <div>Perhaps you should go activate a park!</div>
        </v-card-text>
      </v-card>
    </v-flex>
    -->

    <!-- Display card view for mobile browsers -->
    <v-flex
      xs12
      sm6
      lg4
      xl3
      v-for="item in spots"
      :key="item.spotId"
      class="text-xs-left"
    >
      <v-card :color="getItemColor(item)">
        <v-card-title primary-title class="title">
          <!-- may want to fiddle with this some more -->
          <user-stats :callsign="item.activator">
            &nbsp;&nbsp; @ &nbsp;&nbsp; {{ item.reference }}</user-stats
          >
          <v-spacer></v-spacer>
          <spot-worked
            :worked="item.hunted"
            :activator="item.activator"
            :reference="item.reference"
            :band="item.band"
            :mode="item.mode"
          ></spot-worked>
        </v-card-title>
        <v-divider />
        <v-list color="transparent" dense>
          <v-list-item v-if="item.reference">
            <v-list-item-action
              ><v-icon color="primary" small>{{
                $vuetify.icons.values.mdiMapMarker
              }}</v-icon></v-list-item-action
            >
            <v-list-item-content class="text-xs-left body-2">
              <span>
                <template v-if="isNewPark(item)">◈&numsp;</template>
                <router-link
                  :to="{ name: 'park', params: { id: item.reference } }"
                  >{{ item.reference }}&numsp;{{ item.name }}</router-link
                >
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.locationDesc">
            <v-list-item-action
              ><v-icon color="primary" small>{{
                $vuetify.icons.values.mdiCity
              }}</v-icon></v-list-item-action
            >
            <v-list-item-content
              class="text-xs-left body-2"
              v-if="item.locationDesc.includes(',')"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{
                      item.locationDesc
                        .split(",")[0]
                        .concat(", + ", item.locationDesc.split(",").length - 1)
                    }}
                  </span>
                </template>
                <span>{{ item.locationDesc }}</span>
              </v-tooltip>
            </v-list-item-content>
            <v-list-item-content class="text-xs-left body-2" v-else
              ><template v-if="isNewLocation(item)">◈&numsp;</template
              >{{ item.locationDesc }}</v-list-item-content
            >
          </v-list-item>

          <v-list-item v-if="item.frequency">
            <v-list-item-action
              ><v-icon color="primary" small>{{
                $vuetify.icons.values.mdiVideoInputAntenna
              }}</v-icon></v-list-item-action
            >
            <v-list-item-content
              v-if="item.mode && item.mode != 'SSB'"
              class="text-xs-left body-2"
              >{{ item.frequency }} kHz ({{ item.mode }})</v-list-item-content
            >
            <v-list-item-content v-else class="text-xs-left body-2"
              >{{ item.frequency }} kHz</v-list-item-content
            >
          </v-list-item>

          <v-list-item v-if="item.spotter">
            <v-list-item-action
              ><v-icon color="primary" tooltip="Spotter" small>{{
                $vuetify.icons.values.mdiClipboardAccount
              }}</v-icon></v-list-item-action
            >
            <v-list-item-content class="text-xs-left body-2"
              ><user-stats :callsign="item.spotter" :rbn="item.rbn"></user-stats
            ></v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action
              ><v-icon color="primary" small>{{
                $vuetify.icons.values.mdiMessageBulleted
              }}</v-icon></v-list-item-action
            >
            <v-list-item-content class="text-xs-left body-2">{{
              item.comments || "No spot information"
            }}</v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action
              ><v-icon color="primary" small>{{
                $vuetify.icons.values.mdiClockTimeFour
              }}</v-icon></v-list-item-action
            >
            <v-list-item-content class="text-xs-left body-2"
              >Last heard {{ item.date | dateTimeCard }} at
              {{ item.date | time }} UTC</v-list-item-content
            >
          </v-list-item>
        </v-list>
        <v-divider />
        <v-card-actions>
          <spot-form
            title="Re-Spot"
            buttoncolor="primary"
            :buttonicon="$vuetify.icons.values.mdiAutorenew"
            :spot="item"
          />
          <spot-history
            :activator="item.activator"
            :reference="item.reference"
            :count="item.count"
          ></spot-history>
          <v-spacer></v-spacer>
          <spot-remove :spotId="item.spotId" />
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex v-if="isAuthenticated" xs12 sm6 lg4 class="text-xs-left">
      <spot-form card requireComment />
    </v-flex>
    <v-flex v-else xs12 class="text-xs-right">
      At a park now? <a @click="doSignIn"><b>Login to add your spot!</b></a>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.quiet-links a:link {
  color: inherit !important;
  text-decoration: none;
}
.quiet-links a:visited {
  color: inherit !important;
  text-decoration: none;
}
.quiet-links a:hover {
  color: inherit !important;
  text-decoration: underline dashed;
}
</style>

<script>
import SpotForm from "@/components/SpotForm";
import SpotRemove from "@/components/SpotRemove";
import UserStats from "@/components/UserStats";
import SpotHistory from "@/components/SpotHistory";
import SpotWorked from "@/components/SpotWorked";

import { Auth } from "aws-amplify";

export default {
  components: {
    SpotForm,
    SpotRemove,
    UserStats,
    SpotHistory,
    SpotWorked,
  },
  computed: {
    spots: {
      get() {
        return this.$store.state.spots.filtered;
      },
    },
    sort: {
      get() {
        return this.$store.state.spots.sort;
      },
    },
    callsign: {
      get() {
        return this.$store.state.user.callsign;
      },
    },
    hunted: {
      get() {
        return this.$store.state.user.hunted;
      },
    },
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated;
      },
    },
    isSpotAdmin: {
      get() {
        return this.$store.state.user.isSpotAdmin;
      },
    },
    loadingSpots: {
      get() {
        return this.$store.state.spots.loading;
      },
    },
    highlightNewSpots: {
      get() {
        return this.$store.state.config.highlightNewSpots;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    doSignIn: function () {
      Auth.federatedSignIn();
    },
    getItemColor(item) {
      if (item.user) return this.$vuetify.theme.currentTheme.highlight;
      if (item.hunted || item.qrt) return this.$vuetify.theme.currentTheme.dim;
      if (this.highlightNewSpots && this.sort != "Time" && item.age < 60)
        return this.$vuetify.theme.currentTheme.new;
      return this.$vuetify.theme.currentTheme.normal;
    },
    isNewPark(item) {
      return item.spotId == 0;
    },
    isNewLocation(item) {
      return item.spotId == 0;
    },
  },
};
</script>

<template>
  <v-card flat class="hidden-xs-only quiet-links">
    <v-data-table
      :headers="headers"
      hide-default-footer
      disable-pagination
      disable-sort
      :items="activations"
    >
      <!-- <v-progress-linear slot="progress" color="green lighten-3" indeterminate></v-progress-linear> -->
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.scheduledActivitiesId">
            <td class="text-xs-left pa-1 text-no-wrap">
              {{ item.startDate }} {{ item.startTime }}
            </td>
            <td class="text-xs-left pa-1 text-no-wrap">
              {{ item.endDate }} {{ item.endTime }}
            </td>
            <td class="text-xs-left pa-1 text-no-wrap">
              <user-stats :callsign="item.activator"></user-stats>
            </td>
            <td class="text-xs-left pa-1 text-no-wrap width20 ellipsis">
              <span
                ><router-link
                  :to="{ name: 'park', params: { id: item.reference } }"
                  >{{ item.reference }}&numsp;{{ item.name }}</router-link
                ></span
              >
            </td>
            <td
              class="text-xs-left pa-1 text-no-wrap"
              v-if="
                item.locationDesc != null && item.locationDesc.includes(',')
              "
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{
                      item.locationDesc
                        .split(",")[0]
                        .concat(", + ", item.locationDesc.split(",").length - 1)
                    }}
                  </span>
                </template>
                <span>{{ item.locationDesc }}</span>
              </v-tooltip>
            </td>
            <td
              class="text-xs-left pa-1 text-no-wrap"
              v-else-if="item.locationDesc != null"
            >
              {{ item.locationDesc }}
            </td>
            <td v-else></td>
            <td class="text-xs-left pa-1">{{ item.frequencies }}</td>
            <td class="text-xs-left pa-1 width35">{{ item.comments }}</td>
            <td class="text-right px-1 min">
              <activation-remove
                :activationId="item.scheduledActivitiesId"
                :userId="item.schedulerUserId"
                icon
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="9" style="text-align: right">
              Will you be at a park later?
              <a v-if="!isAuthenticated" @click="doSignIn"
                ><b>Login to schedule your activation!</b></a
              >
              <activation-form :pactivator="callsign" v-else></activation-form>
            </td>
          </tr>
        </tfoot>
      </template>

      <template v-slot:no-data>
        <tfoot>
          <tr>
            <td colspan="7">
              <div>There are currently no scheduled activations.</div>
              <div>Perhaps you should add your next activation!</div>
            </td>
            <td style="text-align: right">
              <a v-if="!isAuthenticated" @click="doSignIn"
                ><b>Login to schedule your activation!</b></a
              >
              <activation-form :pactivator="callsign" v-else></activation-form>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
.row-dim {
  background-color: var(--v-dim-base) !important;
}
.row-highlight {
  background-color: var(--v-highlight-base) !important;
}
.row-new {
  background-color: var(--v-new-base) !important;
}
.row-normal {
  background-color: var(--v-normal-base) !important;
}
</style>

<script>
import { mapState } from "vuex";
import ActivationForm from "@/components/ActivationForm";
import ActivationRemove from "@/components/ActivationRemove";
import UserStats from "@/components/UserStats";

import { Auth } from "aws-amplify";

export default {
  components: { ActivationForm, ActivationRemove, UserStats },
  props: ["activations", "loadingActivations"],
  computed: mapState({
    callsign: (state) => state.user.callsign,
    isAuthenticated: (state) => state.user.isAuthenticated,
    //activations: state => state.activations.data,
    //loadingActivations: state => state.activations.loading
  }),
  methods: {
    doSignIn: function () {
      Auth.federatedSignIn();
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Start (UTC)",
          value: "activityStart",
          class: "text-xs-left pa-1",
          align: "left",
        },
        {
          text: "End (UTC)",
          value: "activityEnd",
          class: "text-xs-left pa-1",
          align: "left",
        },
        {
          text: "Activator",
          value: "activator",
          class: "text-xs-left pa-1",
          align: "left",
        },
        {
          text: "Park",
          value: "reference",
          class: "text-xs-left pa-1",
          align: "left",
        },
        //{ text: 'Name', value: 'name', class: 'text-xs-left pa-1', align: 'left' },
        {
          text: "Location",
          value: "locationDesc",
          class: "text-xs-left pa-1",
          align: "left",
        },
        {
          text: "Frequencies",
          value: "frequencies",
          class: "text-xs-left pa-1",
          align: "left",
          sortable: false,
        },
        {
          text: "Comments",
          value: "comments",
          class: "text-xs-left pa-1",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "",
          align: "right",
          sortable: false,
          class: "text-xs-right px-1",
        },
      ],
    };
  },
};
</script>
